import React from "react";
import Image from "next/image";
import logoHouzz from "../images/logos/houzz.svg";
import logoLAtimes from "../images/logos/la_times.png";
import logoTL from "../images/logos/travel_and_leisure.png";
import logoSpuce from "../images/logos/the_spruce.svg";
import logoAD from "../images/logos/architectural_digest.svg";
import logoElle from "../images/logos/elle-decor.svg";
import CoreContainer from "components/core-container";

function Logos() {
  return (
    <CoreContainer>
      <div className="my-8 px-12 md:px-0 lg:my-8">
        <p className="text-center text-sm font-medium text-[#7C7B78]">As seen in</p>
        <ul
          role="list"
          className="mt-2 flex items-center justify-center gap-x-7 sm:flex-col sm:gap-x-0 sm:gap-y-10 md:mt-8 xl:flex-row xl:gap-x-7 xl:gap-y-0"
        >
          {[
            [
              { name: "Kitchen and Bath Design News", logo: logoTL },
              { name: "Houzz", logo: logoHouzz },
              { name: "LA Times", logo: logoLAtimes },
              { name: "Architectural Digest", logo: logoAD },
              { name: "The Sprice", logo: logoSpuce },
              { name: "Elle Decor", logo: logoElle },
            ],
          ].map((group, groupIndex) => (
            <li key={groupIndex}>
              <ul role="list" className="grid grid-cols-3 gap-x-8 gap-y-2 md:grid-cols-6 md:gap-y-8">
                {group.map((company) => (
                  <li key={company.name} className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                    <Image src={company.logo} alt={company.name} width={99} height={53} unoptimized />
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </CoreContainer>
  );
}
export default Logos;
